/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:

	Eric Meyer					:: http://meyerweb.com
	HTML5 Doctor				:: http://html5doctor.com
	and the HTML5 Boilerplate	:: http://html5boilerplate.com

-------------------------------------------------------------------------------*/

/* Let's default this puppy out
-------------------------------------------------------------------------------*/

html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video, details, summary {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */

article, aside, figure, footer, header, hgroup, nav, section, details, summary {display: block;}

/* Responsive images and other embedded objects
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
   If this default setting for images is causing issues, you might want to replace it with a .responsive class instead. */
img,
object,
embed {max-width: 100%; height: auto;}

/* force a vertical scrollbar to prevent a jumpy page */
html {overflow-y: scroll;}

/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul {list-style: none;}

blockquote, q {quotes: none;}

blockquote:before,
blockquote:after,
q:before,
q:after {content: ''; content: none;}

a {margin: 0; padding: 0; font-size: 100%; vertical-align: baseline; background: transparent;}

del {text-decoration: line-through;}

abbr[title], dfn[title] {border-bottom: 1px dotted #000; cursor: help;}

/* tables still need cellspacing="0" in the markup */
table {border-collapse: collapse; border-spacing: 0;}
th {font-weight: bold; vertical-align: bottom;}
td {font-weight: normal; vertical-align: top;}

hr {display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0;}

input, select {vertical-align: middle;}

pre {
	white-space: pre; /* CSS2 */
	white-space: pre-wrap; /* CSS 2.1 */
	white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
	word-wrap: break-word; /* IE */
}

input[type="radio"] {vertical-align: text-bottom;}
input[type="checkbox"] {vertical-align: bottom;}
.ie7 input[type="checkbox"] {vertical-align: baseline;}
.ie6 input {vertical-align: text-bottom;}

select, input, textarea {font: 99% sans-serif;}

table {font-size: inherit; font: 100%;}

small {font-size: 85%;}

strong {font-weight: bold;}

td, td img {vertical-align: top;}

/* Make sure sup and sub don't screw with your line-heights
	gist.github.com/413930 */
sub, sup {font-size: 75%; line-height: 0; position: relative;}
sup {top: -0.5em;}
sub {bottom: -0.25em;}

/* standardize any monospaced elements */
pre, code, kbd, samp {font-family: monospace, sans-serif;}

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {cursor: pointer;}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {margin: 0;}

/* make buttons play nice in IE */
button {width: auto; overflow: visible;}

/* scale images in IE7 more attractively */
.ie7 img {-ms-interpolation-mode: bicubic;}

/* prevent BG image flicker upon hover */
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}

/* let's clear some floats */
.clearfix:before, .clearfix:after { content: "\0020"; display: block; height: 0; overflow: hidden; }
.clearfix:after { clear: both; }
.clearfix { zoom: 1; }

/* Apply a natural box layout model to all elements: http://paulirish.com/2012/box-sizing-border-box-ftw/ */
* { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; }

.chromeframe {position: absolute; top: 0;}

/* Ok, this is where the fun starts.
-------------------------------------------------------------------------------*/

/* A Linux- and Windows-friendly sans-serif font stack: http://prospects.mhurrell.co.uk/post/updating-the-helvetica-font-stack */
body {font: 13px Helmet, Freesans, sans-serif;}

/* Using local fonts? Check out Font Squirrel's webfont generator: http://www.fontsquirrel.com/tools/webfont-generator */

/* We like off-black for text. */
body, select, input, textarea {color: #333;}

a {color: rgba(42,165,202,1); text-decoration: none;}
a:hover {color: darken(rgba(42,165,202,1), 20%);}

/* Custom text-selection colors (remove any text shadows: http://twitter.com/miketaylr/status/12228805301) */
::-moz-selection{background: rgba(42,165,202,1); color: #fff; text-shadow: none;}
::selection {background: rgba(42,165,202,1); color: #fff; text-shadow: none;}

/*	j.mp/webkit-tap-highlight-color */
a:link {-webkit-tap-highlight-color: rgba(42,165,202,1);}

ins {background-color: #fcd700; color: #000; text-decoration: none;}
mark {background-color: #fcd700; color: #000; font-style: italic; font-weight: bold;}

/* Mozilla dosen't style place holders by default */
input:-moz-placeholder { color:#a9a9a9; }
textarea:-moz-placeholder { color:#a9a9a9; }

/*-------------------------------------------------------------------------------*/
/* @FONT-FACE */
/*-------------------------------------------------------------------------------*/

//Heroic
@font-face {
    font-family: 'heroic_condensedregular';
    src: url('fonts/heroic/heroiccondensed-regular-webfont.eot');
    src: url('fonts/heroic/heroiccondensed-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/heroic/heroiccondensed-regular-webfont.woff') format('woff'),
         url('fonts/heroic/fonts/heroic/heroiccondensed-regular-webfont.ttf') format('truetype'),
         url('fonts/heroic/heroiccondensed-regular-webfont.svg#heroic_condensedregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

//LushIcons
@font-face {
  font-family: 'LushIcons';
  src: url('fonts/lushicons/LushIcons-Regular.eot');
  src: url('fonts/lushicons/LushIcons-Regular.eot?#iefix') format('embedded-opentype'),
       url('fonts/lushicons/LushIcons-Regular.woff') format('woff'),
       url('fonts/lushicons/LushIcons-Regular.ttf')  format('truetype'),
       url('fonts/lushicons/LushIcons-Regular.svg#d273f2b17af35a29b2d7665aeb6deba9') format('svg');
  font-style: normal;
  font-weight: 400;
}


/*-------------------------------------------------------------------------------*/
/* SASS */
/*-------------------------------------------------------------------------------*/
$rccBlue: rgba(42,165,202,1);
$darkGrey: rgba(38,38,38,1);
$grey: rgba(76,76,76,1);
$lightGrey: rgba(242,242,242,1);
$rccRed: rgba(208,81,55,1);
$rccGreen: rgba(96,172,69,1);
$rccYellow: rgba(216,170,67,1);

@mixin heroic {
	font-family: 'heroic_condensedregular', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
	font-weight: normal;
	font-style: normal;
}

@mixin robotoSlab {
	font-family: 'Roboto Slab', Georgia, Times, "Times New Roman", serif;
	font-weight: 400;
	font-style: normal;
}

@mixin roundedCorners {
	-webkit-border-radius: 4px;
    border-radius: 4px;
}

@mixin animate ($attr: all, $time: 0.2s) {
	-webkit-transition: $attr $time ease;
	-moz-transition: $attr $time ease;
	-ms-transition: $attr $time ease;
	-o-transition: $attr $time ease;
	transition: $attr $time ease;
}

@mixin scale ($scale: 2) {
	-moz-transform: scale($scale);
	-webkit-transform: scale($scale);
	-o-transform: scale($scale);
	-ms-transform: scale($scale);
	transform: scale($scale);
}

/*-------------------------------------------------------------------------------*/
/* GLOBAL / DRUPAL OVERRIDES */
/*-------------------------------------------------------------------------------*/
html {
	background: $darkGrey;
	-webkit-font-smoothing: antialiased;
}

body {
	background: #fff;
	@include robotoSlab;
	font-size: 100%;
	color: $grey;
	overflow-x: hidden;
	line-height: 175%;
}

.wrap {
	width: 91.601563%; /*978px / 1024px = 91.601563% | 978px is original width*/
	max-width: 978px;
	margin: 0px auto;
	position: relative;
	}

ul.menu li {
	margin: 0;
	padding: 0;
}

ul.inline li {
	padding: 0;
}

ul li.leaf {
	list-style-image: none;
	list-style-type: none;
}

.item-list ul {
	padding: 0;
	margin: 0;
}

.item-list ul li {
	margin: 0;
	padding: 0;
}

.form-item, .form-actions {
	margin: 0;
}

ul li.expanded, ul li.collapsed {
	list-style-image: none;
	list-style-type: none;
}

i {
	font-style: normal;
}

@import 'come-see';
@import 'unexpected-gifts';

/*------------------------------------------------------------------------------------*/
/* HEADER */
/*------------------------------------------------------------------------------------*/
header {
	position: relative;
	width: 100%;
	z-index: 2;
	background: rgba(255,255,255,1);
	-webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);
	box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);
	@include animate($attr:background);

	&.fade {
		background: rgba(255,255,255,0.95);
	}

	.brand {
		display: block;
		width: 183px;
		height: 57px;
		background: url(i/river-city-logo.svg) center center no-repeat;
		text-indent: -9999px;
		float: left;
		margin-top: 21px;
	}

	nav {
		position: relative;
		@include heroic;
		text-transform: uppercase;
		float: right;

		a#touchMenu {
			display: none;
		}

		ul.menu {
			li {
				float: left;
				position: relative;

				a {
					display: block;
					font-size: 187.5%;
					padding: 35px 25px;
					color: $grey;

					&:hover, &.active-trail, &.over, &.active {
						background: $rccBlue;
						color: #fff;
					}
				}

				ul.menu {
				    display: none;
					background: $rccBlue;
					position: absolute;
					left: 0px;
					z-index: 1;

					li {
						float: none;
						margin: 0;
						border-bottom: 1px solid rgba(255,255,255,0.25);

						&:last-child a {
							border: none;
						}


						a {
							display: block;
							width: 200px;
							padding: 8px 40px 8px 20px;
							font-size: 87.5%;
							color: #fff;
							@include robotoSlab;
							text-transform: none;
							&:hover {
								background: url(i/dropdownArrow.png) 177px center no-repeat lighten($rccBlue, 10%);
							}
						}
					}
				}
			}
		}
	}
}

/*------------------------------------------------------------------------------------*/
/* SECTIONS */
/*------------------------------------------------------------------------------------*/
#slider {
	background: $lightGrey;
	display: block;
	position: relative;
	width: 100%;
	// height: 60vh;
    margin-top: 98px;

    .slide {
        width: 100%;
        text-align: center;

        a {
            display: block;
        }
    }

    img {
        width: 100%;
        max-width: 1500px;
    }

    p.currentSeries {
        display: block;
        width: 153px;
        height: 153px;
        position: absolute;
        top: 0px;
        left: 0px;
        background: url(i/current.svg) center center no-repeat;
        text-indent: -9999px;
    }

    .message {
        position: absolute;
        top: 50%;
        @include heroic;
        font-size: 250%;
        max-width: 50%;
        color: $darkGrey;
        transform: translateY(-50%);

        &.left {
            left: 10%;

            p {
                float: left;
                clear: left;
            }
        }
        &.right {
            right: 10%;
            text-align: right;

            p {
                float: right;
                clear: right;
            }
        }

        p {
            background: rgba(255,255,255,0.9);
            line-height: normal;
            margin-bottom: 1px;
            padding: 5px 10px;
        }
    }
}

.front article {
	margin-top: 0;
}

article {
	// margin-top: 98px;

	//Drupal Tabs
	> .tabs {
		padding: 20px 0 0;
		background: #F08080;
		ul.primary {
			margin: 0;
		}
	}

	.hero {
		background: $lightGrey;
		padding: 40px 0;
		text-align: center;

		img {
			border: 1px solid #bfbfbf;
		}

		&.pageImage {
			padding: 0;
			height: 400px;
			background-position-y: 0px;
			background-repeat: no-repeat;
			background-position: center top;
			-webkit-background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
		}

		&.pageVideo {
			background: #3f3f3f; /* Old browsers */
			background: -moz-linear-gradient(top, #3f3f3f 0%, #191919 100%); /* FF3.6+ */
			background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#3f3f3f), color-stop(100%,#191919)); /* Chrome,Safari4+ */
			background: -webkit-linear-gradient(top, #3f3f3f 0%,#191919 100%); /* Chrome10+,Safari5.1+ */
			background: -o-linear-gradient(top, #3f3f3f 0%,#191919 100%); /* Opera 11.10+ */
			background: -ms-linear-gradient(top, #3f3f3f 0%,#191919 100%); /* IE10+ */
			background: linear-gradient(to bottom, #3f3f3f 0%,#191919 100%); /* W3C */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3f3f3f', endColorstr='#191919',GradientType=0 ); /* IE6-9 */
		}

		.google_map_field_display {
			width: 100%;
			height: 400px;

			img {
				max-width: none;
			}
		}
	}

	section {
		position: relative;
		//background: #fff;

		//General Section Styles
		h1, h2 {
			@include heroic;
			text-transform: uppercase;
			color: $darkGrey;
		}

		h2 {
			font-size: 200%;
			margin-bottom: 10px;
		}

		p {
			margin-bottom: 20px;
		}

		hr {
  		border-color: #ccc;
  		margin: 50px 0;
		}

		a.btn {
			display: block;
			padding: 10px;
			background: $rccBlue;
			color: #fff;
			@include roundedCorners;
			text-align: center;
			@include heroic;
			text-transform: uppercase;
			font-size: 150%;
			border: 1px solid $rccBlue;
			@include animate;
			&:hover {
				background: lighten($rccBlue, 10%);
				border-color: lighten($rccBlue, 10%);
			}

			&.hollow {
				background: none;

				&:hover {
					border-color: #fff;
				}
			}
		}

		//Homepage Sections
		&#mission {
			padding: 75px 0 100px;
			@include heroic;
			background: #fff;

			p {
				width: 60%;
				margin: 0 auto;
				text-align: center;
				font-size: 187.5%;
				line-height: 150%;
				padding: 40px 0;
				//border-top: 5px solid $lightGrey;
				//border-bottom: 5px solid $lightGrey;
				color: $darkGrey;

				&:before {
					content: '';
					height: 5px;
					background: $lightGrey;
					position: absolute;
					top: 0;
					width: 40%;
					left: 50%;
					margin-left: -20%;
				}

				&:after {
					content: '';
					height: 5px;
					background: $lightGrey;
					position: absolute;
					bottom: 0;
					width: 40%;
					left: 50%;
					margin-left: -20%;
				}

			}

			.downArrow {
				position: absolute;
				width: 100%;
				height: 50px;
				margin: 0 auto;
				top: -50px;

				&:before, &:after {
					content: '';
					position: absolute;
					bottom: 0;
					height: 0;
					border-bottom: 50px solid #fff;
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
					-ms-box-sizing: border-box;
					box-sizing: border-box;
				}

				&:before {
					left: 0;
					width: 50%;
					border-right: 1000px solid rgba(0, 0, 0, 0);
				}

				&:after {
					right: 0;
					width: 50%;
					border-left: 1000px solid rgba(0, 0, 0, 0);
				}

				a {
					display: block;
					width: 30px;
					height: 30px;
					background: url(i/downArrow.png) center center no-repeat;
					position: absolute;
					left: 50%;
					margin-left: -15px;
					text-indent: -9999px;
					cursor: pointer;
					z-index: 1;
					top: 0;
				}
			}

		}

		&#series {
			background: $lightGrey;
			border-top: 1px solid darken($lightGrey, 10%);
			border-bottom: 1px solid darken($lightGrey, 10%);
			padding: 75px 0;

			h2 {
				text-align: center;
				background: url(i/h2Border.png) left center repeat-x;
				margin-bottom: 50px;

				span {
					background: $lightGrey;
					padding: 0 20px;
				}
			}

			.recentList {
				margin-bottom: 50px;

				.recent {
					cursor: pointer;
					float: left;
					position: relative;

					h2 {
						background: none;
						margin-bottom: 0;
						text-align: left;
						text-transform: none;
						font-size: 150%;
					}

					p.current {
						position: absolute;
						top: 10px;
						left: -10px;
						background: $rccBlue;
						padding: 5px 10px 2px;
						color: #fff;
						@include heroic;
						font-size: 125%;
						text-transform: uppercase;
						line-height: 100%;
					}
				}
			}

			ul.links {
				width: 50%;
				margin: 0 auto;
				li {
					float: left;
					width: 48.5%;
					margin-right: 3%;

					&:last-child {
						margin-right: 0;
					}

					a {
						display: block;
						width: 100%;

						&.hollow {
							color: $rccBlue;

							&:hover {
								border-color: lighten($rccBlue, 10%);
								color: lighten($rccBlue, 10%);
								background: #fff;
							}
						}
					}
				}
			}

		}

		&#students {
			padding: 75px 0;
			background: url(i/studentsBG.png) repeat-x #fff;
			background-position-x: center;
			background-position-y: 0px;
			text-align: center;

			h2 {
				text-align: center;
				background: url(i/h2Border.png) left center repeat-x;

				span {
					background: #fff;
					padding: 0 20px;
				}
			}

			ul.famMinistries {
				padding-top: 42px;
				li {
					cursor: pointer;
					text-align: center;

					a {
						display: block;
						width: 199px;
						height: 199px;
						background: url(i/famMinistries.png) no-repeat;
						margin: 0px auto 20px;
						text-indent: -9999px;
					}

					h2 {
						text-transform: none;
						background: none;
					}

					p {
						font-size: 87.5%;
					}

					&.wamba {
						a {
							background-position: 0px 0px;
						}

						&:hover a {
							background-position: 0px -199px;
						}
					}

					&.upstreet {
						a {
							background-position: -199px 0px;
							&:hover {

							}
						}
						&:hover a {
							background-position: -199px -199px;
						}
					}

					&.transit {
						a {
							background-position: -398px 0px;
						}
						&:hover a {
							background-position: -398px -199px;
						}
					}

					&.insideout {
						a {
							background-position: -597px 0px;
						}
						&:hover a {
							background-position: -597px -199px;
						}
					}

				}
			}
		}

		&#enjoy {
			background: url(i/enjoyBG.jpg) left center no-repeat fixed;
			-webkit-background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
			color: #fff;
			padding: 100px 0;
			position: inherit;

			p {
				text-align: center;
				font-size: 137.5%;
				width: 60%;
				margin: 0 auto;
			}

			ul {
				width: 50%;
				margin: 0 auto;
				margin-top: 40px;
				li {
					float: left;
					width: 48.5%;
					margin-left: 3%;

					&:first-child {
						margin-left: 0;
					}
					a {
						width: 100%;
					}
				}
			}
		}

		//Interior Page Styles
		&#interiorContent {
			padding: 50px 0;

			h1 {
				font-size: 350%;
				line-height: normal;
				margin-bottom: 20px;
				padding-bottom: 20px;
				border-bottom: 1px solid $lightGrey;
				//text-align: center;

				&.center {
					text-align: center;
					padding-bottom: 0;
					border-bottom: none;
				}
			}

			.content {
				ul.editor {
					margin-bottom: 20px;

					li {
						padding-left: 20px;
						position: relative;

						&:before {
							content: '';
							display: inline-block;
							width: 6px;
							height: 6px;
							background: none;
							border: 2px solid $rccBlue;
							position: absolute;
							left: 0px;
							top: 9px;
							-webkit-border-radius: 50%;
							border-radius: 50%;
						}

						&:hover {
							&:before {
								border-color: transparent;
								background: $rccBlue;
							}
						}
					}
				}

				p.quote {
					@include heroic;
					text-align: center;
					font-size: 225%;
					padding: 50px 0;
					color: $rccBlue;
					line-height: 130%;
					width: 70%;
					margin: 0 auto 20px;
					position: relative;

					&:before {
						content: '';
						width: 50%;
						height: 3px;
						background: $lightGrey;
						position: absolute;
						left: 50%;
						margin-left: -25%;
						top: 24px;

					}

					&:after {
						content: '';
						width: 50%;
						height: 3px;
						background: $lightGrey;
						position: absolute;
						left: 50%;
						margin-left: -25%;
						bottom: 24px;
					}
				}

				img.img_left {
					float: left;
					margin-right: 20px;
					margin-bottom: 10px;
				}
				img.img_right {
					float: right;
					margin-left: 20px;
					margin-bottom: 10px;
				}
			}

			ul.series {

				li {
					float: left;
					width: 33.3%;
					padding: 10px;
					margin-bottom: 20px;

					&:nth-child(3n+1) {
						clear: left;
					}

					&:hover {
						cursor: pointer;
						background: $rccBlue;

						a {
							color: #fff;
						}

						p.seriesDate {
							color: rgba(255,255,255,0.25);
						}
					}

					a {
						@include heroic;
						font-size: 175%;
						color: $grey;
					}

					p.seriesDate {
						font-size: 75%;
						color: #bfbfbf;
					}
				}
			}

			.node-series {
				h1 {
					margin-bottom: 0px;
					text-align: center;
				}

				p.seriesDate {
					text-align: center;
					font-size: 75%;
					margin-bottom: 20px;
					color: #bfbfbf;
				}

				.seriesBody {
					text-align: justify;
					width: 75%;
					margin: 0 auto;
				}
			}

			ul.node-series-list {
				margin-top: 50px;
				> li {
					border-bottom: 1px solid #bfbfbf;
					padding-bottom: 50px;
					margin-bottom: 50px;

					&:last-child {
						border-bottom: none;
						padding-bottom: 0;
						margin-bottom: 0;
					}

					.partInfo {

						h2 {
							margin-bottom: 0;
							a {
								color: $darkGrey;
								&:hover {
									color: $rccBlue;
								}
							}
						}

						p.created {
							color: #bfbfbf;
							font-size: 75%;
							margin-bottom: 10px;
						}

						ul {
							li {
								a {
									&.hollow {
										color: $rccBlue;

										&:hover {
											border: 1px solid $rccBlue;
											color: lighten($rccBlue, 10%);
										}
									}
								}
							}
						}
					}

					img {
						border: 1px solid #bfbfbf;
					}
				}
			}

			.node-series-part {
				h1 {
					margin-bottom: 0px;
				}

				p.seriesDate {
					font-size: 75%;
					margin: 20px 0 50px;
					color: #bfbfbf;
					line-height: normal;
				}

				.sidebar {
					h2 {
						border-bottom: 1px solid #bfbfbf;
						padding-bottom: 5px;
					}

					.download {
						margin-bottom: 50px;

						.hollow {
							color: $rccBlue;

							&:hover {
								border: 1px solid $rccBlue;
								color: lighten($rccBlue, 10%);
							}
						}
					}

					.share {
						.sharethis-buttons {
							* {
								-moz-box-sizing: content-box;
								-webkit-box-sizing: content-box;
								box-sizing: content-box;

							}
						}
					}
				}
			}

			.pageIntro {
				background: $lightGrey;
				padding: 50px 0;
				margin: -50px 0 50px;

				.intro {
					width: 75%;
					margin: 0 auto;
					text-align: justify;
					font-size: 112.5%;
					line-height: 175%;

					p {
						margin-bottom: 0;
					}
				}
			}

			ul.aboutSections {
				//text-align: justify;
				margin-bottom: 100px;

				li {
					margin-bottom: 200px;
					position: relative;
					min-height: 500px;
					&:last-child {
						margin-bottom: 0;
					}

					.sectionImage {
						position: absolute;
						right: 68%;

						img {
							display: block;
							max-width: none;
						}
					}

					.info {
						padding-left: 35%;
					}

					h2.title {
						line-height: 100%;
						margin-bottom: 40px;

						i {
							display: inline-block;
							//margin-bottom: 30px;
							font-size: 150%;
							margin-right: 10px;
						}
					}

					p:last-child {
						margin-bottom: 0;
					}

					&:nth-child(2n) {
						.sectionImage {
							right: auto;
							left: 68%;
						}

						.info {
							padding-left: 0;
							padding-right: 35%;
						}
					}

					&.history {
						h2.title {
							color: $rccRed;
						}
					}

					&.mission {
						h2.title {
							color: $rccGreen;
						}
					}

					&.vision {
						h2.title {
							color: $rccYellow;
						}
					}

					&.beliefs {
						h2.title {
							color: $rccBlue;
						}
					}
				}
			}

			ul.faq {
				margin-bottom: 50px;

				&.body {
					margin-top: 40px;
				}

				li {
					margin-bottom: 20px;
					border-bottom: 1px solid $lightGrey;
					padding-bottom: 40px;

					&:last-child {
						margin-bottom: 0;
						padding-bottom: 0;
						border: none;
					}

					h2 {
						cursor: pointer;
						text-transform: none;
						@include animate;
						margin-bottom: 0;

						&:hover, &.active {
							color: $rccBlue;

							i {
								background-position: center bottom;
							}
						}

						i {
							position: relative;
							top: 17px;
							width: 57px;
							height: 57px;
							margin-right: 10px;
							@include animate;
							display: inline-block;
							border: 3px solid $rccBlue;
							-webkit-border-radius: 50%;
							border-radius: 50%;
							background: url(i/questionMark.svg) center top no-repeat;
							background-size: 50px 100px;
						}
					}

					div {
						display: none;
						padding-left: 20px;
						margin-top: 40px;
						//border-left: 1px solid $lightGrey;
						//margin-left: 20px;

						p {
							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}

			ul.staff {
				&.body {
					margin-top: 40px;
				}

				li {
					margin-bottom: 80px;
					overflow: hidden;

					&:after {
						content: '';
						width: 65.6666666667%;
						//position: relative;
						float: right;
						height: 3px;
						background: $lightGrey;
						visibility: visible;
						margin-top: 50px;
					}

					h2.name {
						margin-bottom: 0;
					}

					p.position {
						color: darken($lightGrey, 20%);
					}

					img {
						border-radius: 50%;
						padding: 10px;
						background: $lightGrey;
					}
				}
			}

			.node-connect-page {

				#next, #familyMinistries, #rccGroups, #outreach {
					padding: 100px 0;

					a {
						width: 50%;
						color: $rccBlue;
						margin-top: 20px;

						&:hover {
							background: $rccBlue;
							border: 1px solid $rccBlue;
							color: #fff;
						}
					}

					h2 {
						font-size: 250%;
						margin-bottom: 20px;
					}

					p {
						margin: 0;
					}
				}

				#next {
					min-height: 150px;
					margin-top: -50px;
				}

				#familyMinistries {
					padding: 150px 0;
					background: url(i/bg-familyMinistries.jpg) center center no-repeat;
					-webkit-background-size: cover;
					-moz-background-size: cover;
					-o-background-size: cover;
					background-size: cover;
				}

				#rccGroups {
					text-align: center;
					color: #fff;
					background: #2aa5ca;
					background: -moz-linear-gradient(top, #2aa5ca 0%, #2491af 100%);
					background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#2aa5ca), color-stop(100%,#2491af));
					background: -webkit-linear-gradient(top, #2aa5ca 0%,#2491af 100%);
					background: -o-linear-gradient(top, #2aa5ca 0%,#2491af 100%);
					background: -ms-linear-gradient(top, #2aa5ca 0%,#2491af 100%);
					background: linear-gradient(to bottom, #2aa5ca 0%,#2491af 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2aa5ca', endColorstr='#2491af',GradientType=0 );

					h2 {
						color: #fff;
						margin-bottom: 50px;
					}

					img {
						margin-bottom: 50px;
					}

					a {
						display: block;
						border-color: #fff;
						color: #fff;
						width: 25%;
						margin: 20px auto 0;

						&:hover {
							background: #fff;
							border-color: #fff;
							color: $rccBlue;
						}
					}
				}

				#outreach {
					margin-bottom: -50px;
					//background: #ff;
					-webkit-background-size: cover;
					-moz-background-size: cover;
					-o-background-size: cover;
					background-size: cover;
				}
			}

			.node-family-ministries-page {

				.content {
					.title {
						padding: 100px 0;
						margin-bottom: 20px;

						h2 {
							margin-bottom: 0;
							display: block;
							text-indent: -9999px;
						}
					}
				}

				#wamba, #upstreet, #students {
					margin-top: 100px
				}

				#wamba {
				}

				#upstreet {
					.image {
						float: right;
					}

					.text {
						margin: 0 3% 0 0;
					}

				}

				#students {
					margin-bottom: 100px;
				}
			}

			.node-contact-page {

				h2.title {
					padding-bottom: 10px;
					margin-bottom: 10px;
					border-bottom: 1px solid $lightGrey;
					position: relative;

					&:after {
					width: 50%;
					height: 3px;
					content: " ";
					position: absolute;
					bottom: -2px;
					left: 0;
					background: $rccBlue;
					}
				}

				.contactInfo {
					margin-bottom: 50px;
					position: relative;

					p {
						margin-bottom: 5px;
					}

					a {
						color: $grey;

						&:hover {
							color: $rccBlue;
						}
					}

					.span4 {
						position: relative;
					}

					i {
						margin-right: 5px;
					}

					.address {
						i {
							float: left;
						}
					}

					ul.social {
						li {
							float: left;
							margin-right: 10px;

							&:last-child {
								margin-right: 0;
							}

							a {
								display: block;
								width: 33px;
								height: 32px;
								background-image: url(i/social.png);
								background-size: 132px 64px;
								text-indent: -9999px;
							}

							&.twitter {
								a {
									background-position: 0px 0px;

									&:hover {
										background-position: 0px -32px;
									}
								}
							}

							&.facebook {
								a {
									background-position: -33px 0px;

									&:hover {
										background-position: -33px -32px;
									}
								}
							}

							&.vimeo {
								a {
									background-position: -66px 0px;

									&:hover {
										background-position: -66px -32px;
									}
								}
                            }

							&.instagram {
								a {
									background-position: -99px 0px;

									&:hover {
										background-position: -99px -32px;
									}
								}
							}
						}
					}

				}

				.form {
					position: relative;
					background: $lightGrey;
					padding: 30px;

					h2.title {
						border-bottom: 1px solid #fff;
					}

					form {
						.form-item {
							position: relative;
							margin-bottom: 20px;

							input, textarea {
								padding: 10px;
								width: 100%;
								border: none;
								background: #fff;

								&:focus {
									outline: none;
								}
							}

							label {
								position: absolute;
								top: 5px;
								left: 10px;
							}

							&.webform-component-select {
								label {
									position: relative;
									top: auto;
									left: auto;
								}
							}
						}
					}
				}
			}

			#node-351 {
  			.intro {
    			text-align: center;
  			}
			}

		}
	}

	#breadcrumb {
		font-size: 75%;
		position: absolute;
		top: -40px;
		right: 0px;
		color: $grey;
		z-index: 1;

		a {
			color: $grey;
		}

		.current {
			color: $rccBlue;
		}
	}

}

footer {
	position: relative;
	background: $darkGrey;
	z-index: 1;
	color: #ffffff;
	font-size: 87.5%;
	line-height: normal;

	a {
		color: #ffffff;

		&:hover {
			color: $rccBlue;
		}
	}

	h2 {
		@include heroic;
		font-size: 200%;
		margin-bottom: 20px;
	}

	.social {
		border-bottom: 1px solid lighten($darkGrey, 5%);
		margin-bottom: 50px;

		h2 {
			float: left;
			text-align: right;
			margin: 0 10px 0 0;
			@include robotoSlab;
			font-size: 87.5%;
			line-height: normal;
			margin-top: 10px;

		}

		.wrap > div {
			float: right;
		}

		a {
			display: block;
			width: 40px;
			height: 40px;
			float: left;
			text-indent: -9999px;
            border-left: 1px solid lighten($darkGrey, 5%);
            z-index: 1;

			&.facebook {
				background: url(i/facebook.svg) center center no-repeat;
				background-size: 20px 20px;

				&:hover {
					background-color: #3b5998;
				}
			}

			&.twitter {
				background: url(i/twitter.svg) center center no-repeat;
				background-size: 20px 20px;

				&:hover {
					background-color: #55acee;
				}
			}

			&.vimeo {
				background: url(i/vimeo.svg) center center no-repeat;
				background-size: 20px 20px;
				border-right: 1px solid lighten($darkGrey, 5%);

				&:hover {
					background-color: #1ab7ea;
				}
            }

            &.instagram {
				background: url(i/instagram.svg) center center no-repeat;
				background-size: 20px 20px;
                border-right: 1px solid lighten($darkGrey, 5%);
                z-index: 1;

				&:hover {
					background-color: #DC2F75;
				}
			}
		}
	}

	.credits {
		border-top: 1px solid lighten($darkGrey, 5%);
		margin: 25px 0 0 0;
		padding: 25px 0;
		font-size: 87.5%;
		background: url(i/footerLogo.svg) no-repeat;
		background-size: 38px 38px;
		background-position: right center;
		p {
			margin-bottom: 5px;
		}
	}

	ul {
		li {
			margin-bottom: 10px;

			p {

			}

			i {
				float: left;
				margin-right: 10px;
				margin-bottom: 1px;
				width: 13px;
			}
		}
	}
}

/*------------------------------------------------------------------------------------*/
/* MEDIA QUERIES */
/*------------------------------------------------------------------------------------*/

@media screen and (max-width: 1024px) {

	article {
		.hero {
			&.pageImage {
				height: 250px;
			}

			.google_map_field_display {
				height: 250px;
			}
		}

		section {
			&#mission {
				.downArrow {
					&:before, &:after {
						border-bottom: 30px solid #fff;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 900px) {
	header {
		nav {
			ul.menu {
				li {
					a {
						padding: 35px 15px;
					}
				}
			}
		}
	}

	article {
		section {
			&#students {
				background-position-y: 50% !important;
				.col {
					margin-left:0;
				}

				ul.famMinistries {
					li {
						margin-bottom: 20px;

						&.span3 {
							width: 48.5%;
						}

						&:nth-child(2n) {
							margin-left: 3%;
						}
					}
				}
			}

			&#interiorContent {
				ul.aboutSections {
					li {
						.sectionImage {
							right: 58%;
						}

						.info {
							padding-left: 45%;
						}

						&:nth-child(2n) {
							.sectionImage {
								right: auto;
								left: 58%;
							}

							.info {
								padding-left: 0;
								padding-right: 45%;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	#slider {
        .message {
            font-size: 200%;
        }
	}

	article {
		section {
			&#mission {
				p {
					width: 100%;

					&:before, &:after {
						width: 80%;
						margin-left: -40%;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 700px) {
	header {
		height: 58px;

		.brand {
			width: 121px;
			height: 38px;
			margin-top: 10px;
		}

		nav {
			float: none;
			@include robotoSlab;
			text-transform: none;

			a#touchMenu {
				display: block;
				float: right;
				font-size: 125%;
				margin-top: 15px;
				@include heroic;
				text-transform: uppercase;
				color: $grey;

				i {
  				//font-size: 150%;
  				margin-left: 10px;
          top: -2px;
          position: relative;
          color: $rccBlue;
          display: inline-block;
          width: 21px;
          height: 23px;
				}

				i.lush-close {
					font-size: 150%;
					//top: -3px;
					position: relative;
					color: $rccRed;
					line-height: 0;
				}

				&:hover {
					//color: $rccBlue;

				}
			}

			> ul.menu {
				display: none;
				background: $rccBlue;
				position: absolute;
				left: 0;
				top: 58px;
				width: 100%;
				z-index: 10;
				-webkit-box-shadow:inset 0 5px 5px -5px rgba(0,0,0,0.5);
				box-shadow:inset 0 5px 5px -5px rgba(0,0,0,0.5);

				li {
					float: none;
					width: 100%;
					border-bottom: 1px solid lighten($rccBlue, 3%);

					a {
						font-size: 100%;
						padding: 5px 20px;
						color: #fff;

						&:hover, &.active-trail, &.over, &.active {
							background: none;
						}
					}

					ul.menu {
						position: relative;
						background: darken($rccBlue, 3%);

						li {
							border-bottom: 1px solid $rccBlue;

							a {
								width: 100%;
								padding: 0px 40px;

								&:hover {
									background: url(i/dropdownArrow.png) 20px center no-repeat;
								}

							}

							&:last-child {
								border-bottom: none;
							}
						}
					}
				}
			}
		}
	}

	#slider {
		margin-top: 58px;
	}

	article {
		// margin-top: 58px;

		#breadcrumb {
			display: none;
		}

		.hero {
			&.pageImage {
				height: 150px;
			}

			.google_map_field_display {
				height: 150px;
			}
		}

		section {
			&#series {
				.recentList {
					.span4 {
						width: 100%;
					}

					.col {
						margin-left: 0;
					}

					.recent {
						text-align: center;
						margin-bottom: 20px;

						h2 {
							text-align: center;
						}

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}

			&#enjoy {
				p {
					width: 100%;
				}
			}

			&#interiorContent {
				h1 {
					font-size: 250%;
				}

				.content {
					p.quote {
						width: 100%;
					}
				}

				.pageIntro {
					.intro {
						width: 100%;
						font-size: 100%;
					}
				}

				ul.series {
					li {
						width: 50%;

						&:nth-child(3n+1) {
							clear: none;
						}

						&:nth-child(2n+1) {
							clear: left;
						}

						p.seriesDate {
							margin-bottom: 0;
						}
					}
				}

				.node-series {
					.seriesBody {
						width: 100%;
						text-align: left;
					}
				}

				ul.node-series-list {
					li {
						.partInfo {
							width: 100%;

							ul {
								li {
									width: 48.5%;
								}
							}
						}

						.partImage {
							display: none;
						}
					}
				}

				.node-series-part {
					.span8, .span4 {
						width: 100%;
					}

					.col {
						margin-left: 0;
					}

					.download {
						.span3 {
							width: 48.5%;
						}

						a {
							margin-left: 3%;

							&:first-of-type {
								margin-left: 0;
							}
						}
					}
				}

				ul.faq {
					li {
						h2 {
							text-align: center;

							i {
								display: block;
								margin: 0 auto 30px;
							}
						}
					}
				}

				.node-contact-page {

					h2.title {
						&:after {
							width: 75%;
						}
					}

					.contactInfo {
						margin-bottom: 0;

						.span4 {
							width: 100%;
							margin-bottom: 40px;
						}

						.col {
							margin-left: 0;
						}
					}

					.form {
						.span6 {
							width: 100%;
						}

						.col {
							margin-left: 0;
						}
					}
				}

				.node-connect-page {
					text-align: center;

					.span6 {
						width: 100%;
					}

					.col {
						margin-left: 0;
					}

					#next, #familyMinistries, #rccGroups, #outreach {
						padding: 50px 0;
						a {
							margin: 20px auto 0;
							width: 50%;
						}
					}

					#familyMinistries {
						padding: 100px 0;
						background: url(i/bg-familyMinistries-mobile.jpg) center center no-repeat;
					}

				}

				.node-family-ministries-page {
					#wamba, #upstreet, #students {
						margin-top: 50px
					}

					.span6 {
						width: 100%;
					}

					.col {
						margin: 0;
					}

					.image {
						text-align: center;
					}

					#upstreet {
						.col {
							margin: 0;
						}
					}

					#students, #wamba {
						.image {
							margin-bottom: 50px;
						}
					}


				}

				ul.staff {
					li {
						&:after {
							width: 100%;
						}
					}

					.span4, .span8 {
						width: 100%;
					}

					.col {
						margin-left: 0;
					}

					.image {
						text-align: center;
						margin-bottom: 10px;
					}

					h2.name, p.position {
						text-align: center;
					}
				}

				ul.aboutSections {
					li {
						margin-bottom: 50px;

						.sectionImage {
							position: relative;
							right: auto;
							margin-bottom: 40px;

							img {
								max-width: 100%;
							}
						}

						h2.title {
							text-align: center;

							i {
								display: block;
								margin-bottom: 20px;
								margin-right: 0;
							}
						}

						.info {
							padding-left: 0;
						}

						&:nth-child(2n) {
							.sectionImage {
								right: auto;
								left: auto;
							}

							.info {
								padding-left: 0;
								padding-right: 0;
							}
						}

					}
				}
			}
		}
	}

	footer {

		.span4 {
			width: 100%;
			margin-bottom: 40px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.col {
			margin-left: 0;
		}
	}
}

@media screen and (max-width: 600px) {

    #slider {
        p.currentSeries {
            width: 80px;
            height: 80px;
        }

        .message {
            font-size: 125%;

            p {
                padding: 2px 5px;
            }
        }
    }

	article {
        #interiorContent {
            #node-351 {
                .content {
                    img.img_left {
                    float: none;
                    display: block;
                    margin: 0 auto 20px;
                    }
                }
            }
        }

		section {
			&#students {
				background: #fff;

				.col {
					margin-left:0;
				}

				ul.famMinistries {
					li {
						&.span3 {
							width: 100%;
						}

						&:nth-child(2n) {
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 400px) {

	article {
		margin-top: 58px;

		section {
			&#mission, &#series, &#students, &#enjoy {
				padding: 50px 0;
			}

			&#mission {
				.downArrow {
					display: none;
				}

				p {
					padding: 20px 0;
					font-size: 175%;
					line-height: 125%;
				}
			}

			&#series {
				ul.links {
					li {
						margin-right: 0;
						width: 100%;
						margin-bottom: 10px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}

			&#enjoy {
				ul {
					li {
						margin-left: 0;
						width: 100%;
						margin-bottom: 10px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}

			&#interiorContent {
				ul.series {
					li {
						width: 100%;
						height: auto;

						&:nth-child(2n+1) {
							clear: none;
						}
					}
				}
			}
		}
	}
}

/*------------------------------------------------------------------------------------*/
/* RETINA GRAPHICS
/*------------------------------------------------------------------------------------*/
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {

}

/*------------------------------------------------------------------------------------*/
/* PRINT STYLES */
/*------------------------------------------------------------------------------------*/
@media print {

}
