@keyframes collage {
  0% { transform:translate3d(0, 0, 0); }
  100% { transform:translate3d(-2332px, 0, 0); }
}

$cream        : #f5eee2;
$christmasRed : #e2333e; 

html.unexpected-gifts {
  background-color: $christmasRed;
  height: 100%;

  @media screen and (max-width: 550px) {
    height: auto;
  }
}

.page-node-468 {
  // height: 100vh;
  background-color: $christmasRed;

  header {
    background: transparent;
    box-shadow: none;
    padding: 20px;

    @media screen and (max-width: 550px) {
      padding: 0;
    }
    
    &.fade {
      background: transparent;
    }

    svg {
      width: 45px;
      display: inline-block;
      vertical-align: middle;
      margin: 20px;
      
      path {
        fill: $christmasRed;
      }
    }
    
    @media screen and (max-width: 768px) {
      .midnightHeader.dark {
        svg {
          path {
            fill: $cream;
          }
        }
      }
    }
  }
  
  p {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  #unexpected-gifts {
    color: $cream;
  }

  #banner, #welcome {
    width: 50%;
    text-align: justify;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  
  svg {
    width: 100%;
  }

  #banner {
    position: fixed;
    height: 100vh;
    top: 0; left: 0;
    background-color: $cream;
    color: $christmasRed;

    @media screen and (max-width: 768px) {
      height: auto;
      position: relative;
      top: auto; left: auto;
    }

    >div {
      position: relative;
      top: 50%;
      padding: 0 20%;
      transform: translateY(-50%);

      @media screen and (max-width: 768px) {
        top: auto;
        transform: translateY(0);
        padding: 20%;
      }

      @media screen and (max-width: 550px) {
        padding: 30% 10% 20%;
      }
    }
  }

  #welcome {
    position: absolute;
    right: 0; top: 0;
    height: 100%;

    @media screen and (max-width: 768px) {
      position: relative;
      right: auto; top: auto;
    }

    > div {
      position: relative;
      top: 50%;
      padding: 0 20% 20%;
      transform: translateY(-100px);

      @media screen and (max-width: 768px) {
        top: auto;
        transform: translateY(0);
        padding: 20%;
      }

      @media screen and (max-width: 550px) {
        padding: 20% 10%;
      }
    }
    
    a {
      display: block;
      padding: 10px;
      text-align: center;
      width: 50%;
      margin: 50px auto;
      border-radius: 5px;
      border: 2px solid $cream;
      color: $cream;
      transition: .3s all; 

      &:hover {
        background-color: $cream;
        color: $christmasRed;
      }
    }

    .hello {
      width: 50%;
      max-width: 195px;
      margin-bottom: 1em;
    }

    .merry-christmas {
      max-width: 390px;
      margin-top: 1em;
    }
  }

  footer {
    text-align: center;
    padding: 75px 0;
  }
}
