@keyframes collage {
  0% { transform:translate3d(0, 0, 0); }
  100% { transform:translate3d(-2332px, 0, 0); }
}

.page-node-413 {

  header {
    background: transparent;
    box-shadow: none;
    padding: 20px;
    
    &.fade {
      background: transparent;
    }

    svg {
      width: 45px;
      display: inline-block;
      vertical-align: middle;
      margin: 20px;
      
      path {
        fill: #fff;
      }
    }

    a {
      color: #fff;
      display: inline-block;
      float: right;
      margin: 20px;
      padding: 8px 0;

      @media screen and (max-width: 550px) {
        font-size: 87.5%;
      }
      
      svg {
        width: 20px;
        margin: 0;
      }
    }

    .midnightHeader.dark {
      a {
        color: #2aa5ca;
      }

      svg {
        path {
          fill: #2aa5ca;
        }
      }
    }
  }

  #banner {
    position: relative;
    background: transparent;
    box-shadow: none;
    height: 75vh;
    background:transparent url('i/bg--come-see-header.jpg') center center no-repeat;
    background-size: cover;
    color: #fff;
    text-align: center;
    min-height: 575px;

    @media screen and (max-width: 550px) {
      min-height: 500px;
    }

    .hero {
      position: absolute;
      width: 550px;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);

      a {
        @include animate;
        display: inline-block;
        
        &:hover {
          transform: scale(1.1);
        }
      }

      @media screen and (max-width: 550px) {
        width: 300px;
        transform: translateX(-50%) translateY(-40%);
        
        span {
          display: block;
        }

        a svg {
          width: 50px !important;
          height: 50px !important;
        }
      }

      p {
        margin: 20px;
      }
    }
  }

  article {
    margin-top: 0;

    section {
      padding: 75px 0;
    }
  }

  h1,h2,h3 {
    text-transform: none;
    line-height: 1.1;
  }

  h1 {
    font-size: 250%;
  }

  .bg--grey {
    background: $lightGrey;
  }

  .text--center {
    text-align: center;
  }
  
  #welcome {
    h1 {
      margin-bottom: 25px;
    }

    p {
      margin-bottom: 0;
    }

    ul li {
      display: inline-block;
      vertical-align: middle;
      padding: 0 20px;
      
      @media screen and (max-width: 550px) {
        display: block;
        margin-bottom: 10px;
      }

      a {
        color: $grey;
        &:hover {
          color: $rccBlue;
        }
      }

      svg {
        vertical-align: middle;
      }
    }
  }
  
  .collage {
    position: relative;
    height: 500px;
    width: 100%;
    overflow: hidden;
    max-width: inherit;

    .photos {
      position: absolute;
      height: 100%;
      width: 4664px;
      background: transparent url(i/collage.jpg) left top repeat-x;
      transform: translate3d(0, 0, 0);
      animation: collage 60s linear infinite;
    }
  }

  #stay {
    h2 {
      text-align: center;
      background: url(i/h2Border.png) left center repeat-x;
      margin-bottom: 25px;

      span {
        background: #fff;
        padding: 0 20px;
      }
    }

    form {
      max-width: 500px;
      margin: 0 auto;
      
      .form-item {
        position: relative;
        width: 75%;
        float: left;
        
        input {
          width: 100%;
          height: 50px;
          padding-left: 20px;
          border-top: 1px solid darken($lightGrey, 20%);
          border-left: 1px solid darken($lightGrey, 20%);
          border-bottom: 1px solid darken($lightGrey, 20%);
          border-right: none;

          &:focus {
            outline: none;
            border-color: $grey;
          }
        }

        label {
          position: absolute;
          top: 10px;
          left: 20px;
          font-weight: normal;
        }
      }

      .form-actions {
        width: 25%;
        float: right;

        input {
          width: 100%;
          height: 50px;
          background: #DC8A4A;
          border: none;
          color: #fff;
          text-transform: uppercase;
          @include heroic;
          font-size: 150%;
          line-height: 1;
        }
      }
    }
  }
  
  #more {
    position: relative;
    .left, .right {
      width: 40%;

      @media screen and (max-width: 550px) {
        width: 100%;
      }
    }
    .left {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      @media screen and (max-width: 550px) {
        position: relative;
        top: auto;
        transform: translateY(0);
        margin-bottom: 25px;
      }
    }
    
    .home-btn {
      background: $rccBlue;
      color: #fff;
      padding: 10px;
      border-radius: 3px;
      @include heroic;
      font-size: 150%;
      text-transform: uppercase;
      margin-top: 10px;
      display: inline-block;
    }

    .right {
      float: right;

      @media screen and (max-width: 550px) {
        float: none;
        text-align: center;
        svg {
          width: 250px;
        }
      }
    }

    ul.social {
      position: relative;
      margin-top: 25px;
      padding-top: 25px;
      border-top: 5px solid darken($lightGrey, 10%);

      li {
        float: left;
        margin-right: 10px;
        
        &:last-child {
          margin-right: 0;
        }
        
        a {
          display: block;
          width: 33px;
          height: 33px;
          background-image: url(i/social.png);
          background-size: 99px 64px;
          text-indent: -9999px;
          background-repeat: no-repeat;
        }
        
        &.twitter {
          a {
            background-position: 0px 1px;
            
            &:hover {
              background-position: 0px -32px;
            }
          }
        }
        
        &.facebook {
          a {
            background-position: -33px 1px;
            
            &:hover {
              background-position: -33px -32px;
            }
          }
        }
        
        &.vimeo {
          a {
            background-position: -66px 1px;
            
            &:hover {
              background-position: -66px -32px;
            }
          }
        }
      }
    }

  }

  footer {
    text-align: center;
    padding: 75px 0;
  }

  .zoom-in {
     /* start state */
    .mfp-content {
      @include animate;
      opacity: 0;
      transform: scale(.5); 
    }
    
    &.mfp-bg {
      @include animate;
      opacity: 0;
    }
    
    /* animate in */
    &.mfp-ready {
      .mfp-content {
        opacity: 1;
        transform: scale(1); 
      }
      &.mfp-bg {
        opacity: 0.5;
      }
    }
    
    /* animate out */
    &.mfp-removing {
      .mfp-content{
        transform: scale(1.5); 
        opacity: 0;
      }
      &.mfp-bg {
        opacity: 0;
      }
    }
  }
}